import { PremiumTiers } from '@bridebook/toolbox';
import type { Slug } from '@bridebook/toolbox/src/types';

const getBySupplierType =
  (props: Partial<Record<Slug, PremiumTiers>> & Record<'default', PremiumTiers>) =>
  (type?: Slug) => {
    if (!type) return props.default;
    return props[type] || props.default;
  };

export const RequiredTiersManager = {
  supplier: {
    venuerexTiers: [PremiumTiers.Tier_2, PremiumTiers.Tier_2_5, PremiumTiers.Tier_3],
    showFeaturedMarker: getBySupplierType({
      venue: PremiumTiers.Tier_2,
      default: PremiumTiers.Tier_1,
    }),
    realWeddings: getBySupplierType({
      venue: PremiumTiers.Tier_2_5,
      default: PremiumTiers.Tier_0,
    }),
    quickResponder: PremiumTiers.Tier_2,
  },
  offers: {
    page: PremiumTiers.Tier_0,
  },
  search: {
    list: PremiumTiers.Tier_2,
    additionalThumbnail: PremiumTiers.Tier_1,
    additionalImages: PremiumTiers.Tier_3,
  },
  videoTours: {
    page: PremiumTiers.Tier_0,
  },
  // Only available to tier_3
  lostLead: PremiumTiers.Tier_3,
};
