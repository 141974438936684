import { FelaCSS } from '../../fela/flowtypes';

interface IStyleProps {
  noPadding?: boolean;
  fullWidth?: boolean;
}

interface IStyles {
  wrap: FelaCSS;
}

export const CONTAINER_SIZE_LG = '1260px';

const styles = ({ noPadding, fullWidth }: IStyleProps): IStyles => ({
  wrap: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: noPadding ? '0' : '10px',
    paddingRight: noPadding ? '0' : '10px',
    width: fullWidth ? '100%' : CONTAINER_SIZE_LG,
    maxWidth: '100%',
    flexShrink: 0,

    maxWidthXsMax: {
      width: '100%',
    },

    maxWidthSmMax: {
      minWidthSm: {
        width: fullWidth ? '100%' : '740px',
      },
    },

    maxWidthMdMax: {
      minWidthMd: {
        width: fullWidth ? '100%' : '960px',
      },
    },
  },
});
export default styles;
