export { default as articleTags } from './articleTags';

/**
 * Representation of Premium tiers as saved in the database (as numbers).
 */
export enum PremiumTiers {
  /**
   * Unverified
   */
  Unverified = -1,
  /**
   * Novice
   */
  Tier_0 = 0,
  /**
   * Basic
   */
  Tier_1 = 1,
  /**
   * Lite/Classic
   */
  Tier_2 = 2,
  /**
   * Classic
   */
  Tier_2_5 = 2.5,
  /**
   * Expert
   */
  Tier_3 = 3,
  /**
   * Platinum
   */
  Tier_4 = 4,
}
