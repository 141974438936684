import { alpha } from '../../../fela-utils/color-mixer';
import { colors } from '../../../themes/variables';
import { FelaCSS } from '../../fela/flowtypes';
import { TButtonSize, TButtonVariant } from './button';

interface IStyles {
  wrapper: FelaCSS;
  button: FelaCSS;
}

interface IProps {
  variant: TButtonVariant;
  size: TButtonSize;
  hasChildren: boolean;
  alternative: boolean;
  isDisabled: boolean;
}

const styles = ({ variant, size, hasChildren, alternative, isDisabled }: IProps): IStyles => {
  const getVariantColors = (): FelaCSS => {
    if (variant === 'primary')
      return {
        backgroundColor: colors.indigoCrush,
        color: colors.white,
        ...(isDisabled
          ? {
              backgroundColor: alpha(colors.space, 40),
              color: colors.space04,
              cursor: 'not-allowed',
            }
          : {
              hover: {
                backgroundColor: colors.indigoCrush80,
                cursor: 'pointer',
              },
              active: {
                backgroundColor: colors.indigoCrush120,
              },
            }),
        ...(alternative && {
          boxShadow: `inset 0px 0px 0px 2px ${colors.white}`,
          backgroundColor: colors.white,
          color: colors.space,
          hover: {
            backgroundColor: colors.space15,
            boxShadow: `inset 0px 0px 0px 2px ${colors.space15}`,
          },
          active: {
            boxShadow: `inset 0px 0px 0px 2px ${colors.space40}`,
            backgroundColor: colors.space40,
          },
        }),
      };

    if (variant === 'secondary')
      return {
        boxShadow: `inset 0px 0px 0px 2px ${alpha(colors.space, 15)}`,
        backgroundColor: 'transparent',
        color: colors.space,
        ...(isDisabled
          ? {
              color: alpha(colors.space, 30),
              cursor: 'not-allowed',
            }
          : {
              hover: {
                backgroundColor: alpha(colors.space, 4),
                cursor: 'pointer',
              },
              active: {
                backgroundColor: alpha(colors.space, 8),
              },
            }),
        ...(alternative && {
          boxShadow: `inset 0px 0px 0px 2px ${colors.white}`,
          color: colors.white,
          hover: {
            backgroundColor: alpha(colors.white, 15),
          },
          active: {
            backgroundColor: alpha(colors.white, 40),
          },
        }),
      };

    if (variant === 'ghost')
      return {
        backgroundColor: 'transparent',
        color: colors.space,
        ...(isDisabled
          ? {
              cursor: 'not-allowed',
              color: alpha(colors.space, 30),
            }
          : {
              hover: {
                backgroundColor: alpha(colors.space, 4),
              },
              active: {
                backgroundColor: alpha(colors.space, 8),
              },
            }),
        ...(alternative && {
          color: colors.white,
          hover: {
            backgroundColor: alpha(colors.white, 15),
          },
          active: {
            backgroundColor: alpha(colors.white, 40),
          },
        }),
      };

    if (variant === 'link')
      return {
        border: 'none',
        color: colors.indigoCrush,
        backgroundColor: 'transparent',
        ...(isDisabled
          ? {
              cursor: 'not-allowed',
            }
          : {
              hover: {
                textDecorationLine: 'underline',
              },
              active: {
                cursor: 'pointer',
                textDecorationLine: 'underline',
              },
            }),
        ...(alternative && {
          color: colors.white,
        }),
        padding: '0',
        width: 'auto',
      };
  };

  const variantStyle = getVariantColors();

  return {
    wrapper: {
      width: '100%',
      justifyContent: 'center',
      borderRadius: 100,
      ...(size === 'large' && {
        paddingHorizontal: 8,
        height: 56,
        fontDefault: 16,
        //Icon only
        ...(!hasChildren && {
          padding: 5,
          width: 56,
        }),
      }),
      ...(size === 'normal' && {
        paddingHorizontal: 6,
        height: 48,
        fontDefault: 15,
        //Icon only
        ...(!hasChildren && {
          padding: 4,
          width: 48,
        }),
      }),
      ...(size === 'small' && {
        height: 34,
        paddingHorizontal: 4,
        fontDefault: 14,
        //Icon only
        ...(!hasChildren && {
          padding: 2,
          width: 34,
        }),
      }),
      ...variantStyle,
      lineHeight: '16px',
      alignItems: 'center',
      border: 'none',
    },
    button: {
      flexDirection: 'row',
      gap: 8,
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

export default styles;
